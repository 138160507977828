// NOTE use this styles from global styles.scss
.sd-confirm-dialog-a11y {
  background: var(--appBackground);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  min-width: 400px;
  max-width: 600px;

  .sd-confirm-dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 16px;
  }
}
