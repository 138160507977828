:root {
  --border-radius: 0.813rem;
  --navbar-height: 3.125rem;
  --header-height: 7.188rem;
  --main-content-template-padding: 1.094rem;
  --max-with-for-dialog-info: 31.25rem;
  --gap-for-flex: 0.5rem;
  --gap-for-column-grid: 0.625rem;
  --gap-for-row-grid: 0.625rem;
  --global-padding-right: 1.4em;
  --global-padding-left: 1.4em;
  --global-padding-top: 0.625rem;
  --global-padding-bottom: 0.625rem;
  --global-margin-rigth: 0.625rem;
  --global-margin-left: 0.625rem;
  --padding-for-description: 1.25em 0;
  --button-border-radius: 4px;
  --button-height: 2.25rem;
  --opacity-for-disabled: 0.5;
}
