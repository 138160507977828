@use './scss/media-queries';

@include media-queries.mobile {
  app-sd-flat-button-a11y > button > span.mdc-button__label > div > span {
    white-space: nowrap !important;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-progress-spinner circle .main {
  stroke: var(--appBackground) !important;
}

.mat-mdc-progress-spinner circle,
.mat-mdc-spinner circle {
  stroke: var(--primaryColor) !important;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--primaryColor) !important;
  --mdc-linear-progress-track-color: var(--backgroundShade) !important;
}

.mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
  color: var(--primaryColor) !important;
}

.mat-mdc-tab-link .mdc-tab-indicator__content--underline {
  border-color: var(--primaryColor) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label--float-above {
  color: var(--primaryColor) !important;
}

.mdc-button {
  min-width: unset !important;
}

.sd-fillable-table
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-top: inherit !important;
  padding-bottom: inherit !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--primaryColor) !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0.125rem !important;
  &__native-control {
    top: 50% !important;
    left: 50% !important;
    height: 48px !important;
    width: 48px !important;
    transform: translate(-50%, -50%) !important;
  }
}
.cdk-overlay-container .mat-mdc-select-panel {
  min-width: 100% !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 16px !important;
}

.mat-mdc-select {
  font-family: var(--main-font-family) !important;
  font-weight: 500 !important;
}

.mdc-text-field--filled .mdc-floating-label {
  font-family: var(--main-font-family) !important;
}

.mat-mdc-option {
  font-family: var(--main-font-family) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: var(--primaryColor) !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary
  .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--primaryColor) !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: var(--primaryColor) !important;
}

.mat-mdc-tab-link {
  font-family: var(--main-font-family) !important;
}

app-sd-infinite-list .mdc-list-item__primary-text {
  width: 100% !important;
  border-bottom: var(--backgroundShade) solid 1px !important;
  padding: 0rem 0 0.425rem 0 !important;
}

.related-record-autocomplete
  .related-record-option
  .mdc-list-item__primary-text {
  width: 100% !important;
  padding: 0.5em 0 !important;
  margin: 0 0 0.5rem 0 !important;
}

.related-record-autocomplete mat-option {
  border-bottom: var(--backgroundShade) solid 1px !important;
}

app-sd-infinite-list .related-record-option {
  margin-bottom: 0.5rem !important;
}

.mat-mdc-menu-item {
  min-height: unset !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background {
  border-color: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.sd-link-menu-a11y-mat-menu-panel {
  //width: 21.875rem !important;
  width: 100% !important;
  max-width: unset !important;
  padding-bottom: 0.5rem !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: var(--primaryColor) !important;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primaryColor) !important;
  --mdc-radio-selected-hover-icon-color: var(--primaryColor) !important;
  --mdc-radio-selected-icon-color: var(--primaryColor) !important;
  --mdc-radio-selected-pressed-icon-color: var(--primaryColor) !important;
  --mat-radio-ripple-color: var(--primaryColor) !important;
  --mat-radio-checked-ripple-color: var(--primaryColor) !important;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--appBackground) !important;
}

.mat-mdc-menu-item a {
  line-height: 1rem !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border-radius: unset !important;
}

.mat-mdc-form-field:not(.mat-form-field-disabled)
  .mat-mdc-floating-label.mdc-floating-label {
  cursor: inherit;
  word-wrap: break-word;
  max-width: 100% !important;
}

.mdc-list-item__primary-text {
  font-size: inherit !important;
  font-family: inherit !important;
  color: inherit !important;
  line-height: inherit !important;
}

.mat-mdc-list-base {
  display: block;
}

.mdc-list {
  padding: 8px 0 0 0 !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: auto !important;
  list-style-type: auto !important;
}

app-plan-field-type-list-item app-sd-time-a11y {
  .label-container {
    position: inherit !important;
  }
  .separator-colon {
    position: relative;
  }
}

.ngx-mat-timepicker {
  &--disabled {
    background-color: transparent !important;
  }
  .period-select {
    width: 5.8em !important;
  }
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
