@use '@angular/material' as mat;
@use 'src/scss/colors';
@use 'src/scss/fonts';
@use 'src/scss/variables';
@use 'src/scss/helpers';
@use 'src/scss/media-queries';
@use 'src/scss/ckeditor5';
@use 'src/scss/quill';
@use './styles-for-material.scss';
@use 'src/app/shared/sd-dialogs/sd-confirm-dialog-a11y/scss/sd-confirm-dialog-a11y.scss';

@use 'node_modules/quill/dist/quill.core.css' as quill-core;
@use 'node_modules/quill/dist/quill.bubble.css' as quill-bubble;
@use 'node_modules/quill/dist/quill.snow.css' as quill-snow;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
//@use '@angular/material/theming'; // TODO check on Update
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat.m2-define-legacy-typography-config(
  $input: mat.m2-define-typography-level(inherit, 1.15, 400),
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
//@include mat.all-legacy-component-typographies($custom-typography);

@include mat.all-component-typographies($custom-typography);

//@include mat.legacy-core();

@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sdrw-ng-web-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$sdrw-ng-web-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$sdrw-ng-web-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sdrw-ng-web-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $sdrw-ng-web-primary,
      accent: $sdrw-ng-web-accent,
      warn: $sdrw-ng-web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-legacy-component-themes($sdrw-ng-web-theme);

@include mat.all-component-themes($sdrw-ng-web-theme);

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  font-family: var(--main-font-family) !important;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  background-color: var(--appBackground);
  @include media-queries.tablet {
    background-color: var(--webInitialBackground);
  }
}

body {
  margin: 0;
}

h1 {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.font-error {
  font-size: var(--fontSizeForError);
  color: var(--alertsErrors);
  font-weight: var(--fontWeightForError);
  font-family: var(--fontFamilyForError);
}

.mat-progress-spinner circle .main {
  stroke: var(--appBackground) !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primaryColor) !important;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: var(--inactiveButton);
  color: var(--primaryAccentColor);
}

/* .mat-form-field .mat-form-field-label {
  height: 100%;
} */

.mat-form-field-label {
  color: var(--tertiaryAccentColor) !important;
}

.mat-focused .mat-form-field-label {
  color: var(--primaryColor) !important;
}

.mat-focused .mat-form-field-ripple {
  background-color: var(--primaryColor) !important;
}

.mat-input-invalid .mat-input-placeholder {
  color: var(--alertsErrors);
}

.mat-input-invalid .mat-input-ripple {
  background-color: var(--alertsErrors);
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--primaryColor);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--primaryColor);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primaryColor);
}

.mat-calendar-body-selected {
  background-color: var(--primaryColor);
  color: var(--appBackground);
}

.mdc-linear-progress__buffer {
  background-color: var(--backgroundShade) !important;
}

.primary-progress-bar {
  color: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.danger-progress-bar {
  color: var(--alertsErrors) !important;
  background-color: var(--alertsErrors) !important;
}

.success-progress-bar {
  color: var(--successBar) !important;
  background-color: var(--successBar) !important;
}

.main-progress-bar {
  color: var(--gray-7) !important;
}

.sd-nack-bar,
.mat-tooltip {
  font-family: var(--main-font-family) !important;
  background-color: var(--primaryAccentColor);
}

.mat-option,
.mat-select-value {
  font-family: var(--main-font-family) !important;
  min-height: 1.6rem !important;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
  .mdc-switch__shadow {
  background-color: var(--primaryColor);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
  .mdc-switch__track::after {
  background-color: var(--tertiaryAccentColor) !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: var(--appBackground) !important;
}

.mdc-switch:enabled .mdc-switch__track::before {
  background-color: var(--tertiaryAccentColor) !important;
}

.mdc-switch:enabled .mdc-switch__shadow {
  box-shadow:
    0 2px 1px -1px #0003,
    0 1px 1px #00000024,
    0 1px 3px #0000001f !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: var(--appBackground) !important;
}

//TODO REMOVE PAGE CLASS
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include media-queries.tablet {
    background: var(--primaryColor);
    background: linear-gradient(
      142deg,
      var(--primaryColor) 0%,
      var(--primaryColorSemiTransparent) 100%
    );
    width: 100%;
    min-height: 100vh;
  }
}

.login-content {
  background-color: var(--appBackground);
  padding: 30px;
  overflow-x: hidden;

  @include media-queries.tablet {
    border: solid 1px var(--backgroundShade);
    border-radius: 6px;
    margin: 20px;
    max-width: 500px;
  }
}

.base-dialog {
  position: relative;
  max-width: 500px;
  max-height: 80vh;

  app-alert-box {
    margin: 0 -40px;
    width: 100%;
  }

  .base-dialog-close-button {
    position: absolute;
    top: -40px;
    right: -20px;
    color: var(--primaryColor);
  }

  .base-dialog-title {
    font-family: var(--main-font-family);
    color: var(--primaryColor);
    font-size: 1.125rem;
    text-align: center;
    font-weight: 600;
    margin: 20px auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .base-dialog-content {
    font-family: var(--main-font-family);
  }

  .base-dialog-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    app-sd-flat-button {
      width: 100%;
    }
  }

  .base-dialog-multiple-buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 36px 36px;
    grid-row-gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    grid-template-areas:
      'primary'
      'secondary';
    //height: 102px;

    @include media-queries.tablet {
      height: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 36px;
      grid-column-gap: 10px;
      grid-template-areas: 'secondary primary';
    }

    app-sd-flat-button {
      width: 100%;
    }

    app-sd-flat-button.secondary {
      grid-area: secondary;
    }

    app-sd-flat-button.primary {
      grid-area: primary;
    }
  }
}

.home-views-template {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .home-views-container {
    .home-views-header {
      margin: 60px 0 30px;
    }

    .home-views-content {
      height: calc(100vh - 190px);
      margin: 0;
    }

    .box-style {
      background-color: var(--appBackground);
      border-radius: 6px;
      border: solid 1px var(--backgroundShade);
      min-height: calc(100vh - 192px);
    }
  }
}

.home-view-spinner {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mat-button {
  min-width: 0 !important;
}
//TODO REMOVE PAGE CLASS
.sd-icon-button {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--appBackground) !important;
  border-radius: 4px !important;
  border: var(--backgroundShade) solid 1px !important;
  height: 36px;
  width: 36px;
  padding: 0 !important;

  .sd-icon-button-icon {
    background-color: var(--primaryColor);
    width: 14px;
    height: 14px;
  }
}

.spacer {
  flex: 1;
}

.hidden {
  display: none;
}

.visible {
  display: initial;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.content-headers-back-button {
  color: var(--primaryColor);
  background-color: var(--appBackground);
  font-family: var(--main-font-family);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  height: 36px !important;

  mat-icon {
    margin-bottom: 2px;
    width: 20px;
  }
}

.right-menu-tooltip {
  margin-right: 0 !important;
}

.left-menu-tooltip {
  margin-left: 0 !important;
}

.border {
  border-bottom: var(--backgroundShade) 1px solid;
  height: 1px;
}

.no-padding-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 0 !important;
}

.spinner-progress-text {
  font-family: var(--main-font-family);
  font-size: 15px;
  font-weight: 700;
  color: var(--secondaryAccentColor);
  margin-right: 5px;
}

.estate-summary-chart .chart-legend .legend-labels {
  background: none !important;
  text-align: center !important;
  margin-top: -60px;
}

.sd-text-button {
  color: var(--primaryColor);
  text-decoration: none;
  text-align: center;
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  cursor: pointer;
}
// Drag and Drop Record files.
.record-upload-snackbar {
  background-color: var(--appBackground);
  height: 4rem !important;
  display: block;
}
// Overlay container snackbar info request drag and drop specific html position.
.drag-drop-overlay-container-snackbar {
  position: absolute;
}
// reminders list item
.reminder-list-item-info-sentence {
  span {
    &.date.bold,
    &.frequency.bold,
    &.iteration.bold {
      font-weight: bold;
      color: var(--secondaryAccentColor);
    }
  }
}

.sd-container-fixed {
  min-width: 100%;
  position: fixed;
  z-index: 901;
  top: 3.1rem;

  @include media-queries.desktop {
    min-width: auto;
    width: calc(100% - 12.5rem);
  }
}

.custom-dialog-container-for-reminders-form-dialog-a11y {
  max-height: 95%;
  max-width: 95vw !important;

  @include media-queries.desktop {
    max-width: 90vw;
    max-height: 85%;
  }
}

.custom-dialog-container-for-reminders-form-dialog-a11y
  .mat-mdc-dialog-container {
  padding-bottom: 0 !important;
}

.custom-share-resource-dialog {
  .mdc-dialog .mdc-dialog__content {
    padding-bottom: 0.1em !important;
  }
}

.custom-dialog-form-licenses-mobile {
  max-width: 90vw;

  @include media-queries.desktop {
    width: 70vw !important;
  }
  @include media-queries.tablet {
    width: 80vw;
  }
}

//TODO REMOVE THIS WHEN THE THEMING IS UPDATED
.mat-mdc-dialog-container .mdc-dialog__surface {
  display: block;
  padding: 0;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: unset;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.mat-mdc-dialog-container .mdc-dialog__title::before {
  display: none;
}

.mat-mdc-tooltip {
  color: var(--appBackground);
  background-color: var(--primaryAccentColor);
  font-family: var(--main-font-family) !important;
  font-size: 75%;
  margin-left: 0.375em;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: 75%;
  border-radius: 3%;
}

.mat-mdc-tooltip-trigger .tooltip-container .ng-star-inserted {
  color: var(--appBackground);
  background-color: var(--tdGrayTertiary);
  font-family: var(--main-font-family) !important;
  margin-left: 0.375em;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;

  &:focus {
    background-color: var(--appBackground);
  }
}

:host ::ng-deep input::placeholder {
  font-family: var(--main-font-family);
  color: var(--gray-9);
  font-size: 0.75rem;
}

::ng-deep .mat-mdc-tooltip-trigger .tooltip-container-small {
  color: var(--appBackground);
  background-color: var(--tdGrayTertiary);
  &:focus {
    background-color: var(--appBackground);
  }
}

.info-request-media-embedded-video {
  max-width: 100%;
  height: auto;
  border: none;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: block;
  @include media-queries.desktop {
    max-width: 640px;
    height: 360px;
    width: 640px;
  }
}

.signature-workflow-dialog {
  width: 95vw !important;
  max-width: 95vw !important;
  height: 95vh !important;
  max-height: 95vh !important;
  overflow: hidden !important;

  .sd-dialog-template-header {
    padding: 15px 24px 11px 24px !important;
  }

  .mat-mdc-dialog-content {
    max-height: 95vh !important;
  }
}

.custom-overlay-position {
  top: 433px !important;
  height: 420px;

  .mat-mdc-menu-panel {
    height: 312px;
  }

  .mat-mdc-menu-content {
    height: 300px;
  }

  .sd-link-menu-search-section-container {
    height: 266px;
  }
}
