$small: 600px;
$medium: 1025px;

@mixin tablet {
  @media (min-width: #{$small}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin mobile {
  @media(max-width: #{$small}) {
    @content;
  }
}