:root {
  --appBackground: #ffffff; // 00
  --primaryColor: #ff9500; // 01
  --primaryAccentColor: #404040; // 02
  --secondaryAccentColor: #535353; // 03
  --tertiaryAccentColor: #9e9e9e; // 04
  --backgroundShade: #dadada; // 05
  --accentBackground: #f0f4f6; // 06
  --headerBackground: #f7f7f7; // 07
  --inactiveButton: #f2f2f2; // 08
  --alertsErrors: #ff0000; // 09
  --checkbox: #3369ff; // 10
  --progressBar: #00bb66; // 11
  --webInitialBackground: #f7f7f7; //
  --primaryColorSemiTransparent: #fff4e6; // 98
  --appCameraBackground: #000000; // 99
  --successBar: #00bb00;
  --tdGray: #f9f9f9;
  --tdGraySecondary: #f0f0f0;
  --tdGrayTertiary: #1c1c1c;
  --tdEmptyState: #767676;
  

  // TODO REMOVE
  --blue-1: #e7edf3;
  --blue-2: #dde6ee;
  --blue-3: #cfd8e0;
  --blue-4: #c7d2dd;
  --blue-5: #3183c8;
  --blue-6: #1a4971;

  --gray-0: #ffffff;
  --gray-1: #f8f9fa;
  --gray-2: #f1f3f5;
  --gray-3: #e7ebee;
  --gray-4: #dee2e6;
  --gray-5: #cfd4da;
  --gray-6: #adb5bd;
  --gray-7: #868e96;
  --gray-8: #485056;
  --gray-9: #343a40;
  --gray-10: #21242a;
  --gray-11: #101214;
  --gray-12: #000000;

  --yellow-1: #face0080;
  --red: red;
  --white: white;
  --gray: #cccccc;
  --orange: #ff9500;
}
